import React, { useState } from 'reactn';
import qs from 'qs';
import { useLocation } from "@reach/router"
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

const validCategories = {
  '': ['homeware', 'sweatshirts', 't-shirts', 'shirts', 'shirts', 'knitwear', 'scarves', 'upcycle'],
  'Men': ['t-shirts', 'shirts', 'shirts', 'knitwear', 'sweatshirts'],
  'men': ['t-shirts', 'shirts', 'shirts', 'knitwear', 'sweatshirts'],
  'Women': ['scarves', 'shirts', 't-shirts', 'shirts', 'knitwear', 'sweatshirts'],
  'women': ['scarves', 'shirts', 't-shirts', 'shirts', 'knitwear', 'sweatshirts']
}

const replicaEnv = process.env.GATSBY_REPLICA_ENV
const replicas = [
  // "INGMARSON_shopify_" + replicaEnv + "_products_sale",
  "INGMARSON_shopify_" + replicaEnv + "_products_",
  "INGMARSON_shopify_" + replicaEnv + "_products_scarves",
  "INGMARSON_shopify_" + replicaEnv + "_products_knitware",
  "INGMARSON_shopify_" + replicaEnv + "_products_upcycle",
  "INGMARSON_shopify_" + replicaEnv + "_products_sweatshirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_homeware",
  "INGMARSON_shopify_" + replicaEnv + "_products_t_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_men",
  "INGMARSON_shopify_" + replicaEnv + "_products_men_t_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_men_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_men_sweatshirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_women",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_t_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_shirts",
  "INGMARSON_shopify_" + replicaEnv + "_products_men_knitwear",
  "INGMARSON_shopify_" + replicaEnv + "_products_men_upcycle",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_knitwear",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_upcycle",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_scarves",
  "INGMARSON_shopify_" + replicaEnv + "_products_women_sweatshirts",
  "INGMARSON_shopify_" + replicaEnv 
]
  

const allProductIndex = process.env.GATSBY_ACTIVE_ENV == 'production' ? "INGMARSON_shopify_" + replicaEnv + "_products_" : "INGMARSON_shopify_" + replicaEnv + "_products_"
const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;

const searchClient = algoliasearch(appId, searchKey, {
  _useRequestCache: true,
});

const createURL = state => {
  const isDefaultRoute =
    !state.query &&
    state.page === 1 &&
    (state.refinementList && state.refinementList.category && state.refinementList.category.length === 0) &&
    (state.refinementList && state.refinementList.collections && state.refinementList.collections.length === 0) &&
    (state.refinementList && state.refinementList.facet && state.refinementList.facet.length === 0) &&
    (state.refinementList && !state.refinementList.gender);

  if (isDefaultRoute) {
    return '';
  }

  var stateGender = state && state.refinementList && state.refinementList.gender && state.refinementList.gender ? state.refinementList.gender : []
  
  const collectionPath = state && state.refinementList && state.refinementList.collections && state.refinementList.collections.length === 1 
    ? state.refinementList.collections + "/"
    : '';
    var stateGenderedFiltered = stateGender.filter(v => v !== 'both').filter(v => v !== 'all');
    stateGender = stateGenderedFiltered

    const genderPath = (stateGender.length === 1)
      ? stateGender + "/"
      : '';
  
  const categoryPath = state && state.refinementList && state.refinementList.category && state.refinementList.category.length === 1 && validCategories[genderPath.replace("/", "")].includes(state.refinementList.category[0])
    ? state.refinementList.category + "/"
    : (state && state.refinementList && state.refinementList.category && state.refinementList.category.length === 1 && validCategories[genderPath.replace("/", "")].includes(state.refinementList.category[0])
      ? state.refinementList.category + "/"
      : '');
  const queryParameters = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }

  if (state && state.refinementList && state.refinementList.category) {
    queryParameters.category = state.refinementList.category.map(encodeURIComponent)
  }

    if (stateGenderedFiltered) {
      queryParameters.genders = stateGenderedFiltered.map(encodeURIComponent)
    }

  if (state && state.refinementList && state.refinementList.collections) {
    queryParameters.collections = state.refinementList.collections.map(encodeURIComponent)
  }
  if (state && state.refinementList && state.refinementList.facets) {
    queryParameters.facet = state.refinementList.facets.map(encodeURIComponent)
  }
  if (state && state.refinementList && state.refinementList.sizes) {
    queryParameters.sizes = state.refinementList.sizes.map(encodeURIComponent)
  }

  if (state && state.refinementList && state.refinementList.colorName) {
    queryParameters.colorName = state.refinementList.colorName.map(encodeURIComponent)
  }
  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  }).replace(/\%25/g, '%');

  if (collectionPath) {
    return `/products/${collectionPath.toLowerCase()}${queryString}`;
  } else {
    return `/products/${genderPath.toLowerCase()}${categoryPath.toLowerCase()}${queryString}`;
  }
};

// const searchStateToUrl = searchState => 
//   searchState ? createURL(searchState) : '';

const urlToSearchState = (location, pageContext) => {
  const {
    gender,
    collectionSlug,
    facetSlug,
    genderSlug,
    facetGenderSlug,
    categorySlug,
    categoryGenderSlug,
    categoryFacetSlug
  } = pageContext
  
  const { query = '', page = 1, collections = [], category = [], genders = [], facet = [], sizes = [], colorName = [] } = qs.parse(
    location.search.slice(1)
  );

  const allCategories = Array.isArray(category) ? category : [category].filter(Boolean);
  const allFacets = Array.isArray(facet) ? facet : [facet].filter(Boolean);
  const allCollections = pageContext.isCollectionPage ? [pageContext.collection] : Array.isArray(collections) ? collections : [collections].filter(Boolean);
  const allSizes = Array.isArray(sizes) ? sizes : [sizes].filter(Boolean);
  const allGenders = Array.isArray(genders) ? genders : [genders].filter(Boolean);
  const allColorNames = Array.isArray(colorName) ? colorName : [colorName].filter(Boolean);

  facetSlug && !allFacets.includes(facetSlug) && allFacets.push(facetSlug)
  collectionSlug && !allCollections.includes(collectionSlug) && allCollections.push(collectionSlug)
  genderSlug && !allGenders.includes(genderSlug) && allGenders.push(genderSlug)
  facetGenderSlug && !allFacets.includes(facetGenderSlug) && allFacets.push(facetGenderSlug)
  categorySlug && !allCategories.includes(categorySlug) && allCategories.push(categorySlug)
  categoryGenderSlug && !allCategories.includes(categoryGenderSlug) && allCategories.push(categoryGenderSlug)
  categoryFacetSlug && !allCategories.includes(categoryFacetSlug) && allCategories.push(categoryFacetSlug)
  var genderToUse = allGenders && allGenders.length > 0 ? allGenders[0] : gender
  if (!genderToUse) {
    genderToUse = []
  } else {
    if (genderToUse == ['both']) {
      genderToUse = genderToUse
    } else {
      genderToUse = [genderToUse, 'both']
    }
  }
  return {
    query: decodeURIComponent(query),
    page,
    menu: {},
    refinementList: {
      gender: genderToUse,
      category: allCategories.map(decodeURIComponent),
      facets: allFacets.map(decodeURIComponent),
      sizes: allSizes.map(decodeURIComponent),
      colorName: allColorNames.map(decodeURIComponent),
      collections: allCollections.map(decodeURIComponent)
    },
  };
};



const IngmarsonSearch = ({ children, pageContext }) => {
  
  const index = replicas.includes(pageContext.index) ? pageContext.index : allProductIndex
  const location = useLocation();
  const [searchState, setSearchState] = useState(urlToSearchState(location, pageContext));
  const [filters, setFilters] = useState("")
  // console.log("PAgeX", location)
  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location, pageContext);
    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [location]);

  function empotyFunction() {

  }
  function onSearchStateChange(nextSearchState) {
    setSearchState(nextSearchState);
  }

  React.useEffect(() => {
    var filter = ""
    if (!location.pathname.includes('alumni') && !location.search.includes('facet=alumni')) {
      filter = "NOT tags:alumni"
    }  else {
      filter = "tags:alumni"
    }
    
    if (pageContext.isVoucherPage) {
      if (filter != "") {
        filter = filter + " AND "
      }
      filter = filter + "collections:" + pageContext.voucher
    } 
    // Do not show deleted
    if (filter != "") {
      filter = filter + " AND NOT tags:delete"
    } else {
      filter = "NOT tags:delete"
    }
    if (filter != "") {
      setFilters(filter)
    }
  }, [location.pathname]);

  return (
    <InstantSearch
      searchClient={(typeof window !== 'undefined') ? searchClient : empotyFunction}
      searchState={(typeof window !== 'undefined') && !pageContext.isVoucherPage ? searchState : empotyFunction}
      onSearchStateChange={(typeof window !== 'undefined') && !pageContext.voucher ? onSearchStateChange : empotyFunction}
      createURL={(typeof window !== 'undefined') && !pageContext.voucher ? createURL : empotyFunction}
      indexName={index}>
      <Configure
        filters={filters}
        hitsPerPage={18}
        analytics={true}
        clickAnalytics
      />
      {children}
    </InstantSearch>
  )
}

export default IngmarsonSearch

