import React from 'reactn'
import { Box, Image, IconButton } from '@chakra-ui/react'

import { useBreakpointValue } from "@chakra-ui/react"

// import CartIcon from '../../../ingmarson-shopiify-stripe/src/icons/cart';

export function CartButton({ updateGlobal, globalState, store }) {
  // const { store, updateGlobal } = globalState ? globalState : store
  if (!store && globalState) {
    store = globalState.store
    updateGlobal = globalState.updateGlobal
  } 
  console.log("X1CART", store)
  const { cartOpen, checkoutOpen, showPayment, cartTotal, itemsInCart, cartInitialised } = store
  const splitView = useBreakpointValue([false, false, true, true])
  const size = '26px';

  return (
    <Box id="cartButton" height={'100%'} width={'60px'} marginBottom={'6px !important'}
      onClick={() => checkoutOpen ?
        // !splitView ? 
        // setGlobal({checkoutOpen: false}) && setGlobal({showPayment: false}) 
        updateGlobal({
          checkoutOpen: false,
          showPayment: false
        })
        :
        // setGlobal({showPayment: false}) :  
        // setGlobal({cartOpen: true})
        updateGlobal({
          cartOpen: true
        })
      }
    >
      {cartInitialised &&
        <IconButton
          style={{
            position: 'relative !important'
          }}
          colorScheme={'black'}
          aria-label={'Shopping Cart'}
          size={'sm'}
          padding={'unset !important'}
          icon={<>
            <Image src="/images/basket.png" mb={6} height={size} loading='eager' title="Shopping Basket" alt="Shopping Basket" />

            <Box as={'span'} color={'white'} position={'absolute'} top={'11px'} right={'15px'} fontSize={'0.5rem'}
              borderRadius={'llg'} zIndex={9999} p={'1px'}>
              {itemsInCart ? cartTotal.productQuantity : " "}
            </Box>

          </>}
        />
      }

    </Box>
  )
}
