const Text = {
  baseStyle: {
    fontWeight: "500",
    fontSize: '14px',
    marginBottom: '2',
    fontFamily:"ApercuLight",
    lineHeight: "22px",
    whiteSpace: "normal",
    color: 'brandWhite.600',    
    h1: {
      // textTransform: "uppercase",
      fontFamily: "ApercuLight",
      color: "brandWhite.700",
      fontSize: "26px"
    },
    h2: {
      // textTransform: "uppercase",
      fontFamily: "ApercuLight",
      color: "brandWhite.700",
      fontSize: "20px"
    }
  },
  variants: {
    "receipt_header": {
      color: "black",
      marginBottom: '10px',
      fontSize: "18px",
      textTransform: "uppercase",
      fontWeight: "900"
    },
    "checkout_header": {
      color: "black",
      marginBottom: '3px',
      fontSize: "16px",
      textTransform: "uppercase",
      fontWeight: "700"
    },
    "receipt_title": {
      marginBottom: "auto",
      color: "grey.900",
      fontSize: "16px",
      textTransform: "uppercase",
      fontWeight: "900"
    },
    "receipt_text": {
      marginTop: "0px !important",
      fontSize: "16px",
      margin: "0px"
    },
    "receipt_text_upper": {
      marginTop: "0px !important",
      fontSize: "16px",
      textTransform: "uppercase",
      margin: "0px"
    },
    "change_text": {
      marginRight: "10px !important",
      marginTop: "0px !important",
      fontSize: "13px",
      margin: "0px"
    },
    "detail": {
      fontSize: "14px",
      fontWeight: "500",
      a: {
        color: "brandWhite.900"
      }
    },
    "stockist": {
      fontSize: "20px"
    },
    "block": {
      fontSize: "20px",
      lineHeight: '30px'
    },
    "pageHeading": {
      fontSize: "18px",
      lineHeight: '30px',
      // marginBottom: '6',
      p: {
        marginTop: '15px',
        fontSize: ["16px","16px","16px","18px"]
      }
    },    
    "story": {
      fontSize: "16px"
    },
    "scoreHeading": {
      fontSize: "16px",
      fontWeight: "700"
    },
    "scoreText": {
      fontSize: "14px"
    }
  }
}

export default Text
