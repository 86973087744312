import React, {  useState,  useDispatch, useEffect } from 'reactn'
import { Alert, AlertDescription, AlertTitle, Center, Divider, Flex, Input, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
// import { navigate } from 'gatsby';
// import { useLocation } from "@reach/router"
// import { SizeMe } from 'react-sizeme'
// import ShoppingCart from './shoppingCart'
// import { Spinner } from '@chakra-ui/react'
import debounceEffect from '../../debounceEffect';
import { API } from 'aws-amplify'
import './promo.css';

// import Checkout from './checkout'
// import Checkout1 from './checkout1'
import { Box, Menu, SimpleGrid, Heading, Button } from '@chakra-ui/react'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Dots } from '../dotsAnimation';


const Promo = ({globalState}) => {

  const {store, updateGlobal} = globalState
  const {ts, showPayment, promoCode, calculating} = store
  const [fetching, setFetching] = useState(false)
  const setPromoCode = useDispatch('setPromoCode')
  const clearPromoCode = useDispatch('clearPromoCode')
  // const [ts] = useGlobal('ts')
  const [code, setCode] = useState(ts && ts.pc ? ts.pc != false ? ts.pc  : "" : "")
  // const setPromoCodeValidated = useDispatch('setPromoCodeValidated')
  
// alert(ts.pc == false)
  // const [code, setCode] = useState(promoCode && promoCode.promo_code ? promoCode.promo_code : "")
  const [error, setError] = useState(code != "" && ts && ts.pc == false ? "INVALID PROMO CODEXXX" : "")
  // const debouncedError = debounceEffect(error, 5000)
  // const [showPayment] = useGlobal('showPayment')

  // const fetchCart = useDispatch('fetchCart')

  // const fetchData = () => {    
  //   if (code.length == 0) {
  //     return 
  //   }
  //   setFetching(true)
  //   const data = {
  //     // method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     // body: JSON.stringify({ promo_code: promoCode.promo_code })
  //     body: { promo_code: code }
  //   }

  //   console.log("DATA", data)
  //   API
  //     .post('api', '/discount/validate', data)
  //     .then(data => {
  //       // MARK AS VALIDATED WHATEVER        
  //       // alert("GOT DATA BACK")
  //       if (data.promo_code) {
  //         setPromoCodeValidated(data)
  //         if (!data.validCode) {
  //           clearCode()
  //           setError("Invalid Promo Code.")
  //         }

  //       } else {
  //         clearCode()
  //         setError("Please try again later")
  //       }

  //     }).catch(e => {
  //       clearCode()
  //       setError("Failed to validated promo code")
  //     })
  //   setFetching(false)
  // }
  useEffect(() => {
    if (calculating)  {
    setError("")
    } else {
      console.log("TS", ts)
      // alert("SET")
      if (code != ts.pc) {
        setCode(ts && ts.pc ? ts.pc != false ? ts.pc  : "" : "")
      }      
    // alert(code != "" && ts && ts.pc == false ? "Invalid Promo Code" : "")
      setError(code != "" && ts && ts.pc == false ? "INVALID PROMO CODEXXXXXX" : "")
    }
  }, [ts.pc, calculating]);

  // useEffect(() => {
  //   setError("")
  // }, [debouncedError]);

  const onSubmit = (e) => {
    // setFetching(true)

    e.preventDefault();
    setPromoCode(code)
  }

  const setPCode= (code) => {
    if (code.length == 0) {
      clearCode()
    } else {
      setCode(code)
      setError("")
    }
  }
  const clearCode = () => {
    // setFetching(true)
    setCode("")
    setError("")
    clearPromoCode()
  }
  console.log("XXXTS", ts)
  return (
    <Box id="promoCode" mt={5} mb={0}>
      <Divider mt={2} mb={2} /> 
      <Text mb='2px'>{ts && ts.pc != false ? "PROMO CODE APPLIED" : "PROMO CODE"}</Text>
      <form onSubmit={onSubmit}>

        <Flex id='promoflex'>

          <Box flex='1' minHeight={'50px'}>

            <Input variant={'promocode'} ml={'0px !important'} mr={0} disabled={showPayment || fetching || (ts.pc != false )} 
            onChange={(e) => setPCode(e.target.value)} value={code} 
            autoComplete="off" id="code" name="code" label="PROMO CODE" placeholder='PROMO CODE' size='md' minHeight={'50px'} />

          </Box>
          {/* { code.length != 0 &&  */}
          <Box margin={'auto'}>
            {ts.pc != false ?

              <Button variant={'voucher'}
                type={'button'}
                disabled={fetching || showPayment}
                onClick={() => clearCode()}
              >CLEAR</Button>

              :
              <Button variant={'voucher'}
                disabled={fetching || showPayment}
                // leftIcon={fetching ? <Dots mt={0} size='xs' /> : <ArrowBackIcon />}
                type={'submit'}
              >{ fetching ? "VALIDATING" : "APPLY"}</Button>
            }
          
          </Box>
{/* } */}
        </Flex>
        {/* {!calculating && code != "" && error && */}
          <Text minHeight={5}>
            {error}
          </Text>
        {/* } */}
      </form>

    </Box>






  )
}

export default Promo