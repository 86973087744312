import React from "reactn"

import { Box } from '@chakra-ui/react'
// import { use100vh } from 'react-div-100vh'

const ViewHeightComponent = ({ viewHeight, children }) => {
  // const height = use100vh()
  // const actualHeight = height ? height * viewHeight / 100  : '' + viewHeight + 'vh'
  // const actualHeight = height ? height * viewHeight / 100  
  const actualHeight = '' + viewHeight + 'vh'
  return <Box id='ViewHeight' height={actualHeight}>{children}</Box>
}

export default ViewHeightComponent