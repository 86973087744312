import React, { useEffect } from 'reactn'
import { graphql, Link, StaticQuery } from 'gatsby';
import IngmarsonSearch from "../components/ingmarsonSearch"
const aa = require('search-insights');
// import Snipcart from '../components/snipcart'
// import { isReloadRequired, reload } from "./reload"
import Auth from '@aws-amplify/auth'
import { navigate } from "@reach/router"
import { isLoggedIn, signInRequired, setUserID } from "../components/auth/auth"
import { CartSidebar } from "../components/CheckoutPage/cartSidebar"


import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { loadStripe } from '@stripe/stripe-js';
import { useOnlineStatus } from '../components/onlineStatus';

const cookieName = 'ingCookieConsent'
const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;

const CommonState = ({ globalState, snipcartData, pageContext, location, children }) => {

  console.log("COMMON STATE", globalState)
  console.log("LOCATION", location)
  console.log("pageContext", pageContext)
  const { store, updateGlobal } = globalState

  if (location && location.href.includes("#checkout")) {
    updateGlobal({
      checkoutOpen: true,
      cartOpen: true,
    })
  }

  // const {
  //   rebuildDate,
  //   apiEndpoint,
  //   snipcartKey
  // } = snipcartData;
  // const {
  //   rebuildDate,
  //   apiEndpoint
  //   // snipcartKey
  // } = snipcartData;
  // console.log("CHEECK AUTHXXX", location)
  const isSSR = typeof window === "undefined"
  const isOnline = useOnlineStatus();

  const getData = async () => {
    // alert(isOnline) 
    console.log("XXXXXX11GETTING DATA")
    window
      .fetch("https://geolocation-db.com/json/")
      .then(res => {
        // console.log("RETURNED", res)
        return res.json();
      })
      .then(data => {
        console.log("XXXXXX11GETTING DATA SET", data.IPv4, store)
        // alert("SET IP" + data.IPv4)
        updateGlobal({ ip: data.IPv4 })
        try {
          (async () => {
            console.log("X1 LOAD STRIPE")
            const stripe = await loadStripe(process.env.GATSBY_STRIPE_API_KEY);
            console.log("X1 - LOADED STRIPE", stripe)
            updateGlobal({ 
              ip: data.IPv4,
              stripe: stripe,
              cartInitialised: true
              
             })


            stripe.createRadarSession().then(function (result) {
              console.log("RADAR", result)
              if (result.error) {
                console.log("RADAR", result.error)
              } else {
                if (result.radarSession) {
                  updateGlobal({
                    ip: data.IPv4,
                    stripe: stripe,
                    radarID: result.radarSession.id,
                    cartInitialised: true
                  })
                }
              }
            });
          })()
        } catch (e) {
          console.log("FAILED TO LOAD STRIPE")
        }
      }).catch(e => {
        // console.log("FAILED TO SET IP", e)
        // alert("SET IP EMPTHTY")
        console.log("XXXXXX11GETTING DATA CATCH")
        updateGlobal({ ip: "" })
        try {
          (async () => {
            console.log("X1 LOAD STRIPE")
            const stripe = await loadStripe(process.env.GATSBY_STRIPE_API_KEY);
            console.log("X1 - LOADED STRIPE", stripe)
            updateGlobal({ 
              ip: "",
              stripe: stripe,
              cartInitialised: true
             })


            stripe.createRadarSession().then(function (result) {
              console.log("RADAR", result)
              if (result.error) {
                console.log("RADAR", result.error)
              } else {
                if (result.radarSession) {
                  updateGlobal({
                    ip: "",
                    stripe: stripe,
                    radarID: result.radarSession.id,
                    cartInitialised: true
                  })
                }
              }
            });
          })()
        } catch (e) {
          console.log("FAILED TO LOAD STRIPE")
        }
      })


    // (async () => {

    // })

    // setGlobal({ cartInitialised: true })
  }



  if (!isSSR) {
    window.dataLayer = window.dataLayer || [];
  }
  function gtag() {
    console.log("GALL GTAG", arguments)
    window.dataLayer.push(arguments);
  }


  useEffect(() => {
    if (isOnline) {
      console.log("X1 GLOBAL STATE CHANGE", globalState)
      getData()
    }
  }, globalState)
  useEffect(() => {

    async function checkAuth() {
      console.log("CHEECK AUTH", location)
      if (!isLoggedIn() && (!signInRequired || (signInRequired && !location.pathname.startsWith('/admin/login')))) {
        const anonymousUser = await Auth.currentCredentials()
        setUserID(anonymousUser.identityId)
        // console.log("GOT ANON USER", anonymousUser)
      } else {
        console.log("AUTHLOG NOT GETTING ANON AS SIGN IN IS REQUIRED")
        if (isLoggedIn()) {
          // alert("ALREADY SIGNED IN")
        } else {
          // alert("NO ANON REQUESTED")
        }
      }
    }

    checkAuth()




    // if (!isSSR) {
    //   const cookiesAccepted = getCookieConsentValue('ingCookieConsent')
    //   const consent = cookiesAccepted === 'true' ? 'granted' : 'denied'
    //   console.log("COOKIE CONSENT", window.dataLayer)
    //   window.dataLayer.push('consent', 'default',{
    //     ad_storage:  consent,
    //     analytics_storage: consent,
    //     functionality_storage: consent,
    //     personalization_storage: consent,
    //     security_storage: consent,
    //     wait_for_update: 500
    //  })
    // }    

    const cookieEnabled = getCookieConsentValue(cookieName)
    const trackContent = cookieEnabled === 'true' ? "granted" : "denied"
    gtag('set', 'url_passthrough', true);
    gtag("consent", "default", {
      ad_storage: trackContent,
      ad_user_data: trackContent,
      ad_personalization: trackContent,
      analytics_storage: trackContent,
      functionality_storage: "granted",
      personalization_storage: trackContent,
      security_storage: "granted",
      wait_for_update: 500
    });

    aa("init", {
      appId: appId,
      apiKey: searchKey,
    });

    // if (typeof window !== 'undefined') {
    //   if (isReloadRequired(rebuildDate)) {
    //     console.log("RELOADING")
    //     reload()
    //     window.location.reload()
    //   } else {
    //     console.log("RELOAD NOT NEEDED")
    //   }
    // }
  }, [])

  if (typeof window !== 'undefined' && signInRequired() && !isLoggedIn() && location.pathname !== `/admin/login` && location.pathname !== `/admin/signup`) {
    navigate("/admin/login")
    return null
  }



  return (

    <>
      <IngmarsonSearch pageContext={pageContext} location={location} globalState={globalState}>
        {children}
      </IngmarsonSearch>
      <CartSidebar location={location} globalState={globalState} />

      <CookieConsent
        location="bottom"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        onAccept={(acceptedByScrolling) => {
          gtag("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            wait_for_update: 500
          });
        }}
        buttonText="OK"
        cookieName={cookieName}
        style={{ background: "#000000" }}
        buttonStyle={{ background: "white", color: "#000000", fontWeight: 600, fontSize: "14px" }}
        expires={720}
      >We use cookies for a better shopping experience.{" "}
        <span style={{ fontSize: "10px" }}>By accepting, you agree to our <Link to='/privacy'>privacy policy</Link>. </span>
      </CookieConsent>

    </>

  )
}

export default props => (
  <StaticQuery
    query={graphql`
        query SnipcartInfoQuery {
            site {
              siteMetadata {
                rebuildDate
                apiEndpoint
              }
            }
          }
        `}
    render={({ site }) => <CommonState snipcartData={site.siteMetadata} {...props} />}
  />
)

