import React, { useDispatch, useGlobal, setGlobal, useState, useEffect } from 'reactn'
import { API } from 'aws-amplify'
import { QuestionOutlineIcon, RepeatIcon } from '@chakra-ui/icons';
import {
  Box, Button, Center, HStack, Spinner, ButtonGroup, VStack, Divider, Flex, SimpleGrid,
  RadioGroup, Stack, Radio, Container, IconButton, AlertTitle, Accordion, Image
} from "@chakra-ui/react"
import debounceEffect from '../../debounceEffect';
import { FaArrowCircleRight } from 'react-icons/fa';
import { formatPrice } from '../../../utils/format-price';
import { Dots } from '../dotsAnimation';

const ShippingForm = ({ reload, allowSelection, globalState }) => {

  const { store, updateGlobal } = globalState
  const { ts, so, fetcching, fetchingCartData, showShipping, shippingError, fetchingShipping,
    shipping_address,
    emailAddress,
    showPayment,
    ip,
    cartProducts } = store
  // const [rates, setRates] = useGlobal('rates')
  const [packagingOptions] = useGlobal('packagingOptions')
  // const [ts] = useGlobal('ts')
  // const [shippingOptions] = useGlobal('so')
  const [rate, setRate] = useState(ts && ts.shipping_line ? ts.shipping_line.id : so ? so[0].id : "")
  console.log("SELECTED RATE", rate)
  const [pack, setPack] = useState(0)
  // const [fetching, setFetching] = useGlobal('fetching')
  // const [fetchingCartData] = useGlobal('fetchingCartData')
  // const [showShipping, setShowShipping] = useGlobal('showShipping')
  // const [shippingError] = useGlobal('shippingError')
  // const [fetchingShipping] = useGlobal('fetchingShipping')


  // const [currentCartTotal, setCurrentCartTotal] = useGlobal(null)
  const setShipping = useDispatch('setShipping')
  const setPackagingOption = useDispatch('setPackagingOption')
  // const [cartProducts] = useGlobal('cartProducts')
  // const debouncedCartTotal = debounceEffect(cartTotal, 300)
  // const [shippingAddress] = useGlobal('shipping_address')
  const clearShipping = useDispatch('clearShipping')
  const fetchShippingRates = useDispatch('fetchShippingRates')
  // const [emailAddress, setEmailAddress] = useGlobal('emailAddress')
  // const [showPayment, setShowPayment] = useGlobal('showPayment')
  // const [ip] = useGlobal('ip')
  // const [relo = useState(reload)

  const {
    shipping_line = {
      title: ""
    }
  } = ts
  // const [billingAddress] = useGlobal('billing_address')
  // const [shipping] = useGlobal('shipping')

  console.log("SHIPPING OPTIONS", so)
  console.log("SHIPPING OPTIONS", ts)
  console.log("SHIPPING OPTIONS", shipping_line)
  
  const selectRate = (rateNumber) => {
    console.log("RATES", so)
    if (so && so.length > 0) {
      // alert(rateNumber)
      // console.log("XSSX", rates[rateNumber])
      setShip(so[rateNumber])
      setRate(so[rateNumber].id)
      // setShowPayment(true)
    } else {
      clearShipping()
    }
  }

  const setShip = (rate) => {
    setShipping(rate)
  }

  const selectPacak = (packNumber) => {
    console.log("SET PACK", packagingOptions)
    if (packagingOptions && packagingOptions.length > 0) {
      // alert(rateNumber)

      // console.log("XSSX", rates[rateNumber])
      setP(packagingOptions[packNumber])
      setPack(Number(packNumber))
      // setShowPayment(true)
    }
  }

  const setP = (pk) => {
    setPackagingOption(pk)
  }



  useEffect(() => {
    selectRate(0)
  }, [
    shipping_address.country_code]
  );

  useEffect(() => {
    if (ts && ts.shipping_line && ts.shipping_line.id && ts.shipping_line.id != rate) {
      selectRate(0)
    }
  }, [
    ts.shipping_line.id]
  );

  console.log("PACKAGING OPTIONS", packagingOptions)
  console.log("RATES", so)

  return (
    <>
      <Box ml={0} mr={0}>
        <HStack>
          {/* <Box fontSize={16} fontWeight={900} mb={5}>SHIPPING RATES</Box> */}
          {(fetchingShipping) &&
            <Box width="100%">
              <Center>
                <Dots mb={5} size='xs' />
              </Center>
            </Box>
          }
        </HStack>
        <>

          {shippingError ?

            <HStack mt={0}>
              <Box mt={0}>{shippingError}</Box>
              <IconButton border={'none'}
                onClick={() => fetchShippingRates()}
                icon={<RepeatIcon w={5} h={5} color={'black'} />}
                variant={'unstyled'}
                aria-label={'Fetch Shipping Rates'}
              >  </IconButton>

            </HStack>
            :
            <Box ml={[0, 0, 0, 0]} mr={[0, 0, 0, 0]}>
              {/* <Center> */}
              {/* <Box fontSize={14}>SHIPPING RATES</Box> */}
              {allowSelection ?

                <RadioGroup
                  mt={0}
                  value={rate}
                // onChange={(val) => selectRate(val)}
                >
                  <VStack>
                    {so && so.map((rate, index) => (
                      <Button borderWidth={1} disabled={fetchingShipping || fetchingCartData} key={`shi${index}`} minWidth={'100%'} variant={'shipping'} onClick={() => selectRate(index)}>
                        <Flex >
                          <Box textAlign={'left'}>
                            <Radio fontSize={'16px'} colorScheme={'black'} value={rate.id} >{rate.detail} </Radio>
                            <Box fontSize={11} ml={'20px'}>USUALLY DELIVERED WITHIN {rate.delivery} DAYS</Box>
                          </Box>
                          <Box flex='1' fontSize={'16px'} textAlign={'right'} mr={'10px'}>{formatPrice(rate.amount)}</Box>
                        </Flex>
                      </Button>
                    ))
                    }
                  </VStack>
                </RadioGroup>
                :
                // <Button borderWidth={ 0} disabled={fetchingShipping || fetchingCartData}  minWidth={'100%'} variant={'shipping'} onClick={() => selectRate(index)}>
                <Flex >
                  <Box textAlign={'left'}>
                    <Box fontSize={'16px'} colorScheme={allowSelection ? 'black' : 'white'} >{shipping_line.title}</Box>
                    {/* <Box fontSize={11} ml={'20px'}>{shipping_line.delivery}</Box> */}
                  </Box>
                  <Box flex='1' fontSize={'16px'} textAlign={'right'} mr={'10px'}>{formatPrice(shipping_line.price)}</Box>
                </Flex>

                // </Button>
              }

              {/* {/* <Text as="h2" variant="productInfo"  key={`rate-${index}`}>
            {rate.name}
         </Text> */}

              {/* </Center> */}
              {packagingOptions && packagingOptions.length > 0 &&
                <Box id="packiging">
                  <Box textAlign={'left'} fontSize={14} fontWeight={900} mt={4}>PACKAGING</Box>
                  <HStack spacing={2} ml={0} mt={1} mb={1}>
                    <Box textAlign={'left'} fontSize={[12, 12, 12, 12]} fontWeight={900} > MINIMiSE YOUR CARBON IMPACT</Box>
                    <QuestionOutlineIcon w={5} h={5} color={'green'} />
                  </HStack>
                  <Box ml={0} mr={0}>
                    {/* <Center> */}
                    {/* <Box fontSize={14}>SHIPPING RATES</Box> */}
                    <RadioGroup
                      mt={0}
                      value={pack}
                    // onChange={(val) => selectRate(val)}
                    >
                      <VStack>
                        {packagingOptions && packagingOptions.map((pack, index) => (
                          <Button disabled={fetchingShipping || fetchingCartData} key={`shi${index}`} minWidth={'100%'} variant={pack.isCircular ? 'packagingCircular' : 'packaging'} onClick={() => selectPacak(index)}>
                            <Flex>
                              <Box textAlign={'left'} >
                                <HStack spacing={0}>
                                  <Box>
                                    <Radio fontWeight={900} size={pack.isCircular ? 'md' : 'sm'} colorScheme={'black'} value={index} >{pack.description} </Radio>
                                  </Box>

                                  {pack.isCircular &&
                                    <>
                                      <Box width="20px" height='20px' ml={0}><Image margin={0} src='/images/ecl-leaf.svg' /></Box>
                                    </>
                                  }
                                </HStack>


                              </Box>
                              <Box flex='1' textAlign={'right'} textTransform='none' mr={'10px'} minWidth={10}>{pack.costText} </Box>
                            </Flex>
                            <Box ml={pack.isCircular ? '22px' : '20px'} textAlign='left' color={'black'} mt={2} fontWeight={400} fontSize={11} >{pack.benefitShort}</Box>


                            {/* <Box  mt={2} fontWeight={400}   textTransform='none' >{pack.returnInfo}</Box> */}

                          </Button>
                        ))
                        }
                      </VStack>
                    </RadioGroup>

                    {/* {/* <Text as="h2" variant="productInfo"  key={`rate-${index}`}>
            {rate.name}
         </Text> */}

                    {/* </Center> */}
                  </Box>
                </Box>
              }
            </Box>
          }

        </>
        {/* } */}
      </Box>



    </>
  )
}





export default ShippingForm


