import { API } from 'aws-amplify';

const LogRocket = require('logrocket')

const isSSR = typeof window === "undefined"

const gtag = () => {
  console.log("GALL GTAG", arguments)
  window.dataLayer.push(arguments);
}

const createProductFromItems = (items) => {
  var products = []
  var price = 0
  items.forEach(item => {
    products.push(createProductFromItem(item))
    price = price + (item.price * item.quantity)
  });
  console.log("NEW ITEMS", products)
  return {
    items: products,
    price
  }
}

const sendInsight = (eventName, item) => {
  try {
    if (item && item.hasOwnProperty('metadata') && item.metadata != undefined && item.metadata.hasOwnProperty('searchHit')) {
      if (item.metadata.searchObjectID && item.metadata.searchQueryID) {
        aa('convertedObjectIDsAfterSearch', {
          userToken: getUserID(),
          eventName: eventName,
          index: process.env.GATSBY_ALGOLIA_INDEX_NAME,
          queryID: item.metadata.searchQueryID,
          objectIDs: [item.metadata.searchObjectID]
        });
      }
    }
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Send Insight to Angolia", e)
  }
}

const createProductFromItem = (item) => {
  var itemToAdd = {
    item_id: item.sku,
    item_name: item.title,
    item_brand: 'INGMARSON',
    // description: item.description,
    currency: 'GBP',
    // quantity: item.quantity,
    price: item.price,
    quantity: item.quantity ? item.quantity : 1,
  }

  // TWW TODO - ADD CATEGORY
  if (item.metadata && item.metadata.itemLocation && item.metadata.collection) {
    itemToAdd['index'] = item.metadata.itemLocation
    itemToAdd['item_list_name'] = item.metadata.collection
    itemToAdd['item_list_id'] = item.metadata.collection
  }
  console.log("GA4CREATE", itemToAdd)
  return itemToAdd
}

const sendCartEvent = (eventAction) => {
  try {
    console.log('SENDING CART EVENT', eventAction)
    console.log('SENDING CART EVENT - WINDOW.DATA LAYER', window.dataLayer)
    // window.dataLayer.push({ event: eventAction })
    gtag('event', action)
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Error", e)
  }
}

const sendCartItemEvent = (items, eventData, action, eventAction) => {
  console.log("FLATTEN", items, action, eventAction)
  console.log('SENDING CART EVENT - WINDOW.DATA LAYER', window.dataLayer)
  try {

    console.log("GA SENDING ACTION", action)
    console.log("GA SENDING DATA", eventData)
    // ReactGA4.event(
    //   action,
    //   // label: item.name,
    //   eventData
    // )
    gtag('event', action, eventData);
    // window.dataLayer.push({
    //   event: action,
    //   // label: item.name,
    //   ecommerce: eventData
    // }
    // )
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Error", e)
  }
}

const sendCartItsendCartAnalyticsemEvent = (item, action, eventAction) => {
  console.log("SEND CART ANALYTICS", item, action, eventAction)
  try {
    const product = createProductFromItem(item)
    sendCartItemEvent([product], {
      currency: "GBP",
      discount: 0.00,
      value: Number(product.price * product.quantity),
      items: [product],
      // cart: Snipcart.store.getState().cart
    }, action, eventAction)
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Error", e)
  }
}

const informBackend = (type, webhookData) => {
  // alert("INFORM BACKEND")
  try {
    // Call API To Register Event
    const data = {
      body: {
        type: type,
        data: webhookData
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    console.log("CALL API", webhookData, API)
    API
      .post('api', '/snipcartClient', data)
      .then(response => {
        console.log("INFORM BACKEND COMPLETE", response)
      })
      .catch(error => {
        console.log("INFORM BACKEND ERROR", error.response);
      });
  } catch (e) {
    // LogRocket.captureException(e)
    console.log("FAILED TO Inform backend of data", e)
  }
}

const itemRemoved = (item) => {
  try {
    sendCartAnalytics(item, 'remove_from_cart', 'Item Removed from Cart')
    informBackend("ITEM_REMOVED", item)
  } catch (e) {
    console.log("FAILED TO REMOVE ITEM", e)
  }
}


export const cartOpened = (store) => {
  try {
    var products = store.cartProducts

    var { items, price } = createProductFromItems(products)
    sendCartItemEvent(items, {
      currency: "GBP",
      discount: 0.00,
      value: Number(price),
      items: items,
      // cart: snipcartState.cart
    }, 'view_cart', "VIEW CART")
    informBackend("CART_OPENED", {})
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Cart Open", e)
  }
}

const cartClosed = ()  => {
  try {
    var snipcartState = Snipcart.store.getState()
    var products = snipcartState.cart.items.items
    var { items, price } = this.createProductFromItems(products)
    sendCartItemEvent(items, {
      currency: "GBP",
      discount: 0.00,
      value: Number(price),
      items: items,
      // cart: snipcartState.cart
    }, 'cart_closed', "CART CLOSED")
    informBackend("CART_CLOSED", {})
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Cart Open", e)
  }
}

const discountApplied = (discount) => {
  try {
    var snipcartState = Snipcart.store.getState()
    var products = snipcartState.cart.items.items

    var { items, price } = createProductFromItems(products)
    sendCartItemEvent(items, {
      discount: discount,
      currency: "GBP",
      value: Number(price),
      items: items,
      // cart: snipcartState.cart
    }, 'discount_applied', "DISCOUNT APPLIED")

  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log DISCOUNT", e)
  }
}



const paymentFailed = (error) => {
  try {
    var snipcartState = Snipcart.store.getState()
    var products = snipcartState.cart.items.items

    var { items, price } = createProductFromItems(products)
    sendCartItemEvent(items, {
      error: error,
      currency: "GBP",
      value: Number(price),
      discount: 0.00,
      items: items,
      // cart: snipcartState.cart
    }, 'payment_failed', "PAYMENT FAILED")

    informBackend("PAYMENT_FAIL", error)

  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Payment Failed", e)
  }
}

const orderError = (error) => {
  try {
    // Add Items
    console.log("ORDer eRROR", error)
    var snipcartState = Snipcart.store.getState()
    var products = snipcartState.cart.items.items

    var { items, price } = createProductFromItems(products)
    sendCartItemEvent(items, {
      error: error,
      discount: 0.00,
      currency: "GBP",
      value: Number(price),
      items: items,
      // cart: snipcartState.cart
    }, 'order_error', "ORDER ERROR")
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Error", e)
  }
}

const orderCompleted = (order) =>{

  try {
    var { items, price } = createProductFromItems(order.items.items)
    var coupon = ""
    var discount = 0
    // console.log("ORDer DISCOUNTX", order.discounts)
    if (order.discounts && order.discounts.items && order.discounts.items.length > 0) {
      // console.log("ORDER DISCOUNT", order.discounts.items[0])
      var discountItem = order.discounts.items[0]
      coupon = discountItem.code
      discount = Math.abs(discountItem.value)
    }
    sendCartItemEvent(items, {
      transaction_id: order.token,
      coupon: coupon,
      discount: Number(discount),
      currency: "GBP",
      value: Number(order.total),
      shipping: Number(order.shippingDetails.cost),
      items: items,
      order: order
    }, 'purchase', "ORDER COMPLETE")
    // Set User Data
    gtag('set', 'user_data', {
      "email": order.email ? order.email : "",
      "address": {
        "street": order.billingAddress.address1 ? order.billingAddress.address1 : "",  
        "city": order.billingAddress.city ? order.billingAddress.city : "",
        "postal_code": order.billingAddress.postalCode ? order.billingAddress.postalCode : "",
        "country": order.billingAddress.country ? order.billingAddress.country : "",
      }
    })
  } catch (e) {
    LogRocket.captureException(e)
    console.log("FAILED TO Log Order Complete", e)
  }
}

const itemUpdated= (item) => {
  sendCartAnalytics(item, 'update', 'Item Updated in Cart')
}


const preventClick= (e) => {
  e.preventDefault()
  console.log('The prevent link was clicked.')
}


const  itemAdded = async(item) => {
  try {
    console.log("SNIPCART ITEM ADDED", item)
    // console.log("CARTXXXX1", Snipcart.store.getState())
    // console.log("CARTXXXX2", cart)      
    sendCartAnalytics(item, 'add_to_cart', 'Item Added to Cart')
    informBackend("ITEM_ADDED", item)
  } catch (e) {
    console.log("SNIPCART FAILED TO ADD ITEM", e)
  }
}

const handleItemAdding = (item) => {
  console.log('ITEM ADDING', item)
  sendInsight("Product Added to Cart", item)

  itemAdded(item)

  if (window.navigator.onLine) {
    console.log('ADDING - ONLINE')
    return
  }
  // TWW RE_ENABLE LATER
  // console.log('ADDING - ITEM', item)
  // if (item) {
  //   console.log('ADDING - PUSHING', item)
  //   productsQueue.push(item)
  //   // preventDefault(item)
  // }

}

const handleProductClick = (e) =>{
  if (
    e.target.classList.contains('snipcart-add-item') &&
    isSnipcartReady
  ) {
    var item = JSON.parse(e.target.getAttribute('dataset'))
    productsQueue.push(item)
  } else {
    return
  }
}


  
  

  

  

  

//       console.log("ROUTE CHANGED", routesChange)
//       try {
//         var snipcartState = Snipcart.store.getState()
//         if (routesChange.to === "/cart") {
//           var products = snipcartState.cart.items.items
//           console.log("PRODUCTSXXX", products)
//           var { items, price } = createProductFromItems(products)
//           this.sendCartItemEvent(items, {
//             currency: "GBP",
//             discount: 0.00,
//             value: Number(price),
//             items: items,
//             // cart: snipcartState.cart
//           }, 'view_cart', "OPEN CART")
//           if (snipcartState && snipcartState.cart && snipcartState.cart.shippingDetails) {
//             const shippingMethod = snipcartState.cart.shippingDetails.method ? snipcartState.cart.shippingDetails.method : ""
//             if (shippingMethod == "INTERNATIONAL SHIPPING") {
//               this.sendCartItemEvent(items, {
//                 currency: "GBP",
//                 discount: 0.00,
//                 value: Number(price),
//                 shipping_tier: shippingMethod,
//                 items: items,
//                 // cart: snipcartState.cart
//               }, 'fraud_attempt_cart', "FRAUD ATTEMPT")
//               setGlobal({ showPayment: false })
//             } else {
//               this.sendCartItemEvent(items, {
//                 currency: "GBP",
//                 discount: 0.00,
//                 value: Number(price),
//                 shipping_tier: shippingMethod,
//                 items: items,
//                 // cart: snipcartState.cart
//               }, 'non_fraud_attempt_cart', "FRAUD ATTEMPT")
//               setGlobal({ showPayment: true })
//             }

//           }
//           // this.sendCartEvent('CART OPENED')
//         } else if (routesChange.to === "/checkout") {
//           // var snipcartState =  Snipcart.store.getState()        
//           var products = snipcartState.cart.items.items
//           console.log("WOHOO CHECKOUTOUT")
//           console.log("PRODUCTSXXX", products)
//           var { items, price } = this.createProductFromItems(products)
//           this.sendCartItemEvent(items, {
//             currency: "GBP",
//             discount: 0.00,
//             value: Number(price),
//             items: items,
//             // cart: snipcartState.cart
//           }, 'begin_checkout', "OPEN CHECKOUT")
//           if (snipcartState && snipcartState.cart && snipcartState.cart.shippingDetails) {
//             const shippingMethod = snipcartState.cart.shippingDetails.method ? snipcartState.cart.shippingDetails.method : ""
//             if (shippingMethod == "INTERNATIONAL SHIPPING") {
//               this.sendCartItemEvent(items, {
//                 currency: "GBP",
//                 discount: 0.00,
//                 value: Number(price),
//                 shipping_tier: shippingMethod,
//                 items: items,
//                 // cart: snipcartState.cart
//               }, 'fraud_attempt_checkout', "FRAUD ATTEMPT")
//               setGlobal({ showPayment: false })
//             } else {
//               this.sendCartItemEvent(items, {
//                 currency: "GBP",
//                 discount: 0.00,
//                 value: Number(price),
//                 shipping_tier: shippingMethod,
//                 items: items,
//                 // cart: snipcartState.cart
//               }, 'non_fraud_attempt_checkout', "FRAUD ATTEMPT")
//               setGlobal({ showPayment: true })
//             }

//           }

//         }
//       } catch (e) {
//         setGlobal({ showPayment: true })
//         LogRocket.captureException(e)
//         console.log("FAILED TO Log Shipping Method on route change", e)
//       }
//     })

    
//     Snipcart.events.on('shipping.selected', (shippingMethod) => {
//       console.log("SHIPPING SELECTED", shippingMethod);
//       try {
//         var snipcartState = Snipcart.store.getState()
//         var products = snipcartState.cart.items.items
//         console.log("PRODUCTSXXX", products)
//         var { items, price } = this.createProductFromItems(products)
//         this.sendCartItemEvent(items, {
//           currency: "GBP",
//           discount: 0.00,
//           value: Number(price),
//           shipping_tier: shippingMethod,
//           items: items,
//           // cart: snipcartState.cart
//         }, 'add_shipping_info', "SHIPPING SELECTED")
//         // this.sendCartEvent('SHIPPING METHOD SELECTED')
//         if (shippingMethod.method == "INTERNATIONAL SHIPPING") {
//           this.sendCartItemEvent(items, {
//             currency: "GBP",
//             discount: 0.00,
//             value: Number(price),
//             shipping_tier: shippingMethod,
//             items: items,
//             // cart: snipcartState.cart
//           }, 'fraud_attempt', "FRAUD ATTEMPT")
//           setGlobal({ showPayment: false })
//         } else {
//           this.sendCartItemEvent(items, {
//             currency: "GBP",
//             discount: 0.00,
//             value: Number(price),
//             shipping_tier: shippingMethod,
//             items: items,
//             // cart: snipcartState.cart
//           }, 'nonfraud_attempt', "FRAUD ATTEMPT")
//           setGlobal({ showPayment: true })
//         }
//       } catch (e) {
//         setGlobal({ showPayment: true })
//         LogRocket.captureException(e)
//         console.log("FAILED TO Log Shipping Method", e)
//       }

//     });

    
//     Snipcart.events.on('summary.checkout_clicked', () => {
//       try {
//         var snipcartState = Snipcart.store.getState()
//         var products = snipcartState.cart.items.items
//         console.log("PRODUCTSXXX", products)
//         var { items, price } = this.createProductFromItems(products)
//         this.sendCartItemEvent(items, {
//           currency: "GBP",
//           discount: 0.00,
//           value: Number(price),
//           shipping_tier: shippingMethod,
//           items: items,
//           // cart: snipcartState.cart
//         }, 'checkout_clicked', "CHECKOUT CLICKED")
//       } catch (e) {
//         LogRocket.captureException(e)
//         console.log("FAILED TO Log Checkout Clicked", e)
//       }
//     });
//     Snipcart.events.on('item.updated', this.itemUpdated)
//     // Snipcart.events.on('page.change', this.pageChanged)
//     // TODO Snipcart.api.configure('show_continue_shopping', true);
//     // TODO Snipcart.api.configure('split_firstname_and_lastname', true);
//   }

//   isSnipcartLoaded() {
//     return !!window.Snipcart
//   }
//   loadSnipJs() {
//     return this.addBodyElem('script', {
//       async: true,
//       src: 'https://cdn.snipcart.com/themes/v3.4.0/default/snipcart.js'
//     })
//   }

  
//   customiseCart() {
//     return this.getCartHeaderNew() + this.getBillingSection() + this.addPhoneNumber() + this.addBillingHeader();
//     // this.getCart() + this.getCartHeader() + this.addCartSummaryFees() + this.addShippingHeader() + this.addBillingHeader() + this.addCustomOrderFields() + this.getAddressFields()  //this.getShippingAddress() +//+ this.addPaymentSection() //+ this.getCheckout() //+ this.getShippingMethods
//     // return this.getCartHeader() + this.addShippingHeader() + this.addBillingHeader() +  this.addCustomOrderFields() + this.getAddressFields()  //this.getShippingAddress() +//+ this.addPaymentSection() //+ this.getCheckout() //+ this.getShippingMethods
//   }
//   addBodyElem(tag, attrs, innerHTML) {
//     return new Promise((resolve, reject) => {
//       var el = document.createElement(tag)
//       el.onload = resolve
//       el.onerror = reject

//       var keys = Object.keys(attrs)

//       for (var i = 0; i < keys.length; i++) {
//         var key = keys[i]
//         el.setAttribute(key, attrs[key])
//       }

//       if (innerHTML) {
//         el.innerHTML = innerHTML
//       }
//       document.body.appendChild(el)
//     })
//   }

//   render() {
//     return <div>
//       <Helmet lang="en">
//         <script async defer src={snipcartURL} type="text/javascript" />
//       </Helmet>
//       <PaymentVisibility />
//     </div>
//   }
// }

// export default Cart
