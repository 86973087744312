import React, { useDispatch, setGlobal, useState, useEffect } from 'reactn'
import { CountryDropdown } from 'react-country-region-selector';
import countryList from 'react-select-country-list'
import './checkout.css';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import CartError from './cartError'
import {
  Box, Button, Center, HStack, Spinner, VStack, Divider, Flex,
  useBreakpointValue,
  Accordion,
  Checkbox,
  Heading,
  Text,
} from "@chakra-ui/react"
import {
  ExpressCheckoutElement, Elements, AddressElement, LinkAuthenticationElement, useElements,
} from '@stripe/react-stripe-js';




const styleVariables = {
  colorPrimary: '#000',
  colorBackground: '#fff',
  fontWeight: '300',
  borderRadius: '0px',
  fontSize: '14px',
  colorText: '#30313d',
  colorDanger: '#df1b41',
  colorIcon: '#000',
  colorLogo: 'light',
  fontWeightLight: '300',
  fontWeightNormal: '300',
  fontWeightMedium: '300',
  fontFamily: 'ApercuLight, Roboto, Open Sans, Segoe UI, sans-serif',
  spacingUnit: '2px',
}

import AccordionItem from '../accordion/accordianItem';
import PaymentForm from './cartPayment';
import ShippingForm from './sections/shipping';
import ViewHeightComponent from '../viewHeightComponent';
import ShoppingCartTotals from './shoppingCartTotals';
// import Paypal from './sections/paypal';
import { Steps } from './steps';
import { formatPrice } from '../../utils/format-price';
import ExpressPayment from './expressPayment';
import { Dots } from './dotsAnimation';
import { useOnlineStatus } from '../onlineStatus';

const mapKey = process.env.GATSBY_MAP_KEY

const appearance = {
  theme: 'none',
  rules: {
    '.Input': {
      border: '1px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
      fontSize: '14px',
      letterSpacing: 'normal',
      padding: '10px',
      outline: 'none',
      // marginTop: '5px',
      marginBottom: '0px',
      textTransform: 'uppercase',
    },

    '.Input--empty': {
      border: '1px solid #000',
      padding: '10px',
      // marginTop: '5px',
      marginBottom: '5px',
      marginBottom: '0px',
      // borderRadius: '0px',
      // boxShadow: 'none',
      // fontSize: '14px',
      // textTransform: 'uppercase',
    },


    '.AccordionItem': {
      border: '0px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
      textTransform: 'uppercase',
    },

    '.Input:focus-visible': {
      border: '1px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
      textTransform: 'uppercase',
      outline: 'none !important',
    },

    '.PickerItem': {
      border: '0px solid #000',
      borderRadius: '0px',
      paddingLeft: '0px',
      boxShadow: 'none',
      textTransform: 'uppercase',
    },


    '.PickerItem--selected': {
      border: '0px solid #000',
      borderRadius: '0px',
      marginLeft: '0px',
      boxShadow: 'none',
      textTransform: 'uppercase',
    },

    '.CodeInput': {
      border: '1px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    '.MenuAction': {
      textTransform: 'uppercase',
      fontSize: '12px',
      borderRadius: '0px',
    },

    '.PickerAction': {
      textTransform: 'uppercase',
      fontSize: '12px',
      borderRadius: '0px',
    },

    '.Block': {
      border: '1px solid #000',
      borderRadius: '0px',
      marginLeft: '0px',
      boxShadow: 'none',
    },

    '.CheckboxInput': {
      border: '1px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    '.Input:hover': {
      color: 'var(--colorText)',
    },

    '.Input--selected': {
      borderColor: '#000',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    '.Accordion:focus-visible': {
      borderColor: '#000',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    '.Label': {
      textTransform: 'uppercase',
      fontSize: '14px'
    },

    '.TabLabel': {
      textTransform: 'uppercase',
      fontSize: '14px'
    },

    '.Tab': {
      border: '1px solid #000',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    '.Tab:focus': {
      border: '2px solid #000',
      boxShadow: 'none',
    },

    '.Tab--selected:focus': {
      border: '2px solid #000',
      boxShadow: 'none',
    },

    '.Input--invalid': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
    },


    // See all supported class names and selector syntax below
  },
  variables: styleVariables
};




const stripeOptions = (cs) => {
  return {
    clientSecret: cs,
    appearance: appearance
  }
}

const Checkout = ({ globalState }) => {
  const { store, updateGlobal } = globalState
  const { cartTotal, calculating, shipping, pd, ts, os, orderID, stripe, so, soe,
    preparePayment, showPayment, showShipping, accordianItem, addressRequired, marketing,
    accepts_marketing, shipping_address, contactNumber, fullName, emailAddress } = store

  console.log("X1 CHECKOUT", store)
  // const [showPayment, setShowPayment] = useGlobal('showPayment')

  // const [cartTotal] = useGlobal('cartTotal');
  // const [calculating] = useGlobal('calculating')

  const [error, setError] = useState("")
  // const [shipping] = useGlobal('shipping')
  const fetchCart = useDispatch('fetchCart')
  const isOnline = useOnlineStatus();

  // const debouncedCartTotal = debounceEffect(cartTotal, 500)
  // const [pd] = useGlobal('pd')
  // const [ts] = useGlobal('ts')
  // const [os] = useGlobal('os')
  // const fetchShippingRates = useDispatch('fetchShippingRates')
  // const [orderID, setOrderID] = useGlobal('orderID');
  // const [addressRequired] = useGlobal('addressRequired')
  // const [stripe] = useGlobal('stripe')
  const [processing, setProcessing] = useState(false)
  // const completePaypalPayment = useDispatch('completePaypalPayment')
  // const [shippingAddress] = useGlobal('shipping_address')
  // const [contactNumber, setContactNumber] = useGlobal('contactNumber')

  // const [marketing, setMarketing] = useGlobal('marketing')
  // const [accepts_marketing] = useGlobal('accepts_marketing')
  const [addressComplete, setAddressComplete] = useState(false)
  const [formData, setFormData] = useState({})

  function setPreparePayment(preparePayment) {
    updateGlobal({ preparePayment: preparePayment })
  }
  // const [emailAddress, setEmailAddress] = useGlobal('emailAddress')
  // const [eMailAddressState, setEmailAddressState] = useState("")
  // const [contactNumberState, setContactNumberState] = useState("")
  // const [fullNameState, setFullNameState] = useState("")
  // const [marketingState, setMarketingState] = useState("")
  // const [paymentRequest] = useGlobal('paymentRequest')

  // const splitView = useBreakpointValue([false, false, false, false])
  const splitView = useBreakpointValue([false, false, true, true])


  const setAddress = useDispatch('setAddress')


  function setShowShipping(showShipping, accordianItem) {
    updateGlobal({ showShipping: showShipping, showPayment: false, accordianItem: accordianItem })
  }

  function setShowPayment(showPayment) {
    updateGlobal({ showPayment: showPayment })
  }
  function setAccorianItem(accordianItem) {
    updateGlobal({ accordianItem: accordianItem, showPayment: false })
  }

  // const [showShipping, setShowShipping] = useGlobal('showShipping')
  // const [accordianItem, setAccorianItem] = useGlobal('accordianItem')

  // const [currentCountryCode, setCurrentCountryCode] = useState(shippingAddress.country_code)
  const [fetchShipping, setFetchShipping] = useState(false)

  console.log("FILLNAME", fullName)
  console.log("CONTACT N", contactNumber)

  const [visibility, setVisibility] = useState('hidden');
  // const elements = useElements();


  useEffect(() => {
    // alert("ADDRESS UPDATEDING - SAVING")
    fetchCart()
  }, [fetchShipping]);

  useEffect(() => {
    if (preparePayment == true) {
      // alert("PREPARE")
      fetchCart()
    }
  }, [preparePayment]);

  const changeAccordian = (expandedIndex) => {
    if (expandedIndex == 0) {
      setShowShipping(false, expandedIndex)
    } else {
      setAccorianItem(expandedIndex)
    }
  }




  // const selectRate = (rateNumber) => {
  //   console.log("RATES", so)
  //   if (soe && soe.length > 0) {
  //     // alert(rateNumber)
  //     // console.log("XSSX", rates[rateNumber])
  //     setShip(so[rateNumber])
  //     // setRate(so[rateNumber].id)
  //     // setShowPayment(true)
  //   } else {
  //     clearShipping()
  //   }
  // }

  // const setShip = (rate) => {
  //   alert("SET SHIP ->")
  //   console.log("SET SHIP", rate)
  //   setExpressShipping(rate)
  // }



  const buttonMessage = () => {
    if (calculating) {
      return ""
      // return "UPDATING CART ... "
    }
    console.log("ADDRESS REQUIRED", addressRequired)
    console.log("SHOW SHIPPING", showShipping)
    console.log("Shupping Ca  ", shipping.calculated)
    if (!addressRequired) {
      // return !showShipping ? "CONTINUE TO SHIPPING" : "COMPLETE PAYMENT"
      return "COMPLETE PAYMENT"
    } else if (addressRequired && !showPayment && showShipping && ts.shipping_line && ts.shipping_line.title) {
      return "CONTINUE TO PAYMENT"
    } else if (addressRequired && showPayment) {
      return "PAY NOW"
    } else {
      return "UPDATE CART"
    }

  }
  const pressButton = () => {
    if (!addressRequired) {
      completePaypalPayment()
    } else if (addressRequired && !showPayment && showShipping && ts.shipping_line && ts.shipping_line.title) {
      setPreparePayment(true)
    } else if (addressRequired && showPayment) {
      setProcessing(true)
      updateGlobal({ calculating: true, processPayment: true })
      // setGlobal({ processPayment: true })
    } else {
      fetchCart()
    }
  }



  const handleCheckoutSubmit = async (event) => {
    console.log("HANDLE SUBMIT", event)
    event.preventDefault();
    if (showShipping && shipping.calculated) {
      setShowPayment(true)
    } else {
      console.log("FORM   ", formData)
      if (formData.complete) {

        const stripeData = formData.value;
        const stripeAddress = stripeData.address;
        console.log("STRIPE DATA  ", stripeData)
        // setEmailAddress(eMailAddressState)

        let otherData = {}
        otherData['fullName'] = stripeData.name
        otherData['contactNumber'] = stripeData.phone
        otherData['showShipping'] = true
        otherData['accordianItem'] = 1
        otherData['calculating'] = false

        setAddress({
          addressType: 'shipping_address',
          address: {
            // country: address.country,
            country_code: stripeAddress.country,
            zip: stripeAddress.postal_code,
            address1: stripeAddress.line1,
            address2: stripeAddress.line2,
            city: stripeAddress.city,
            province: stripeAddress.state,
            // lat: values.s_lat,
            // long: values.s_long,
            name: stripeData.name,
            // fullAddress: shippingAddress.fullAddress
          },
          otherData: otherData
        })

        // setAddress({
        //   addressType: 'shipping_address',
        //   address: {
        //     country: values.s_country,
        //     country_code: values.s_country_code,
        //     postal_code: values.s_postcode,
        //     street_address1: values.s_addr1,
        //     street_address2: values.s_addr2,
        //     city: values.s_city,
        //     locality: values.s_locality,
        //     lat: values.s_lat,
        //     long: values.s_long,
        //     name: shippingAddress.name,
        //     fullAddress: shippingAddress.fullAddress
        //   }


        // clearShipping()
        // setFetchingShipping(true)
        // setShowShipping(true)
        // setAccorianItem(1)

        // updateGlobal(newData)

        // setFetchShipping(true)
        // fetchShippingRates()
      } else {
        let newData = {}
        newData['showShipping'] = false
        newData['accordianItem'] = 0
        newData['calculating'] = false
        updateGlobal(newData)
        // setShowShipping(false)
        // setAccorianItem(0)
      }
    }
    window.scrollTo(0, 0)
  }

  // const setShipping = (val) => { 
  //   const thisCountry = countryList().getValue(val)
  //   // alert(thisCountry)
  //   // alert(shipping.country_code)
  //   if (thisCountry != shipping.country_code) {
  //     setCurrentCountryCode(thisCountry)
  //     setFetchShipping(true)
  //   } else {
  //     setFetchShipping(false)
  //   }
  // }
  const loader = 'auto';

  console.log("TTTTSSS", shipping_address)
  console.log("TTTTSSSCN", contactNumber)
  // console.log("STRIPE O", stripeOptions)
  console.log("STRIPE ", stripe)
  console.log("MAP", mapKey)
  console.log("ADDRESS COMPLETE", addressComplete)
  console.log("TSSSSS ", ts)
  return (
    <ViewHeightComponent viewHeight={90}>
      {/* <form onSubmit={handleCheckoutSubmit}> */}
      <Flex direction={'column'} height="100%">
        {/* <Steps /> */}
        <Center>
          <Box id="ExpressCheckout" width="100%" maxW={'600px'} >
            {pd && pd.cs &&
              <>
                { }
                <VStack width="100%" >

                  <Box width="100%">
                    <div id="express-checkout-element" style={{ visibility }}>

                      <Elements
                        options={
                          // ...stripeOptions(pd.cs), 
                          {
                            mode: 'payment', 
                            amount: Number(ts.total_price_pence),
                            currency: 'gbp',
                            appearance: {
                              variables: styleVariables
                            }
                          }}
                        stripe={stripe}
                        loader
                      >
                        <ExpressPayment globalState={globalState} setVisibility={setVisibility}/>
                      </Elements>                     
                    </div>

                  </Box>

                  {/* {paypalOptions && paypalAmount &&  */}
                  {/* {orderID && */}
                  {/* <Paypal disabled={calculating} */}
                  {/* orderID={orderID} completePayment={completePaypalPayment} /> */}
                  {/* } */}
                  {/* } */}
                  {/* } */}
                </VStack>
                <>
                  {((orderID || (pd && pd.pi && pd.cs)) && addressRequired) &&
                    <Center>
                      <HStack mt={2} mb={2} width={'100%'}>
                        <Divider mt={2} borderColor="blackAlpha.500" />
                        <Box fontSize={14} color={'brandWhite.700'} textAlign={'center'} minWidth={'50%'}>or pay with other methods</Box>
                        <Divider borderColor="blackAlpha.500" />
                      </HStack>
                    </Center>
                  }
                </>
              </>
            }
          </Box>
        </Center>
        <Box id="MainCheckout" flex="1" overflow={['unset', 'unset', 'scroll', 'scroll']} >

          <Center>
            <Box id="checkout" width="100%" maxW={'600px'} >
              {pd && pd.cs &&
                <>
                  {/* <LinkAuthenticationElement options={{ defaultValues: { email: emailAddress } }} onChange={(event) => {
              alert(event.value.email)
              console.log("EMAIL", event)
              // setEmail(event.value.email);
            }} /> */}

                  {/* {1 == 1 || (clientSecret && stripeOptions && paymentRequest) && */}

                  {/* } */}
                  <Elements
                    fonts={[
                      {
                        family: 'ApercuLight',
                        src: 'url(https://ingmarson.com/onts/apercu-light.woff)',
                        weight: '300'
                      }
                    ]}
                    options={stripeOptions(pd.cs)} stripe={stripe} loader>
                    <Box >
                      {showPayment && addressRequired && accordianItem > 1 &&
                        <>
                          {/* <AccordionItem id="Payment" title="PAYMENT"
                          disabled={!os || !os.ov
                            || !pd
                            || !pd.pi || !showShipping}
                        > */}
                          {pd && pd.pi &&

                            <Box mt={2} id="payment" border={'ActiveBorder'}>
                              <PaymentForm globalState={globalState} />
                            </Box>
                          }
                          {/* </AccordionItem> */}
                          <Divider mt={5} mb={5} borderColor="blackAlpha.500" />
                        </>

                      }
                      {/* <Box mt={2}></Box> */}



                      {/* <Divider mt={5} mb={2} borderColor="blackAlpha.500" /> */}
                      {/* {1 == 1 || !addressRequired || accordianItem <= 1 || !pd || !pd.pi && */}
                      <Box
                        height="100%"
                        marginLeft={'1px'}>
                        <Flex mt={2} mb={2}>
                          <Text
                            // textUnderlineOffset={5} textDecoration={'underline'} 
                            mt={0} variant={'checkout_header'}>CONTACT DETAILS</Text>
                        </Flex>
                        <Box mb={0}>
                          <LinkAuthenticationElement
                            options={{ defaultValues: { email: emailAddress } }}
                            onChange={(event) => {
                              console.log("LINK EVENT", event)
                              // alert("SET EMAIL" + event.value.email)
                              updateGlobal({ emailAddress: event.value.email, showPayment: false })
                              // setAddressRequired(false)
                              // setShowPayment(true)
                            }} />
                        </Box>
                        {/* <Divider mt={5} mb={2} borderColor="blackAlpha.500" /> */}
                        {/* <Divider mb={2} borderColor="blackAlpha.500"/> */}

                        {/* <Accordion allowMultiple={true} width={'95%'} index={accordianItem} onChange={(expandedIndex) => changeAccordian(expandedIndex)} */}
                        {/* defaultIndex={0} id="checkoutAccordian" > */}
                        {/* <Flex mt={0} mb={0}>
                          {accordianItem > 0 &&
                            <Text onClick={() => changeAccordian(0)} textAlign={'right'} flex="1" variant={'change_text'}>CHANGE</Text>
                          }
                        </Flex> */}
                        {addressRequired &&
                          <>

                            {/* <Text textUnderlineOffset={5} textDecoration={'underline'} mt={5} variant={'checkout_header'}>CONTACT DETAILS</Text> */}
                            {/* <AccordionItem id="Address" title="ADDRESS" > */}
                            {/* <InputControl onChange={(e) => setEmailAddressState(e.target.value)} autoComplete="off" type="email" name="sdemasds" id="sdemasds" label="EMAIL" borderRadius={0} /> */}
                            {/* <InputControl onChange={(e) => setContactNumberState(e.target.value)} autoComplete="off" type="phone" name="phoneasd" id="phoneasd" label="PHONE" borderRadius={0} /> */}
                            {/* <InputControl onChange={(e) => setFullNameState(e.target.value)} autoComplete="off" type="text" name="namesds" id="namesds" label="NAME" borderRadius={0} /> */}
                            <Text
                              // textUnderlineOffset={5} textDecoration={'underline'} 
                              mt={5} mb={2} variant={'checkout_header'}>SHIPPING ADDRESS</Text>
                            {accordianItem == 0 ?

                              <AddressElement

                                options={{

                                  defaultValues: {
                                    name: fullName,
                                    phone: contactNumber,
                                    address: {
                                      line1: shipping_address.address1,
                                      line2: shipping_address.address2,
                                      city: shipping_address.city,
                                      state: shipping_address.locality,
                                      postal_code: shipping_address.zip,
                                      country: shipping_address.country_code
                                    }
                                  },
                                  mode: 'shipping',
                                  // allowedCountries: ['US', 'CA'],
                                  fields: {
                                    phone: 'always',
                                  },
                                  validation: {
                                    phone: {
                                      required: 'always',
                                    }
                                  },
                                }}

                                onChange={(event) => {
                                  console.log("STRIPE EVENT", event)
                                  if (event.complete) {
                                    // Extract potentially complete address
                                    setFormData(event)

                                    if (!addressComplete) {
                                      setAddressComplete(true)
                                    }

                                  } else {
                                    if (addressComplete) {
                                      setAddressComplete(false)
                                    }
                                    setShowShipping(false, 0)
                                  }
                                }}
                              />
                              :
                              <Flex mt={2} mb={2}>
                                <Box>
                                  <Text variant={'receipt_text_upper'}>{fullName}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.address1}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.address2}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.city}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.locality}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.zip}</Text>
                                  <Text variant={'receipt_text_upper'}>{shipping_address.country_code}</Text>
                                  <Text variant={'receipt_text_upper'}>{contactNumber}</Text>
                                </Box>
                                <Text onClick={() => changeAccordian(0)} textAlign={'right'} flex="1" variant={'change_text'}>CHANGE</Text>
                              </Flex>
                            }
                            {/* {pd && pd.cs && */}

                            {/* } */}
                            {/* </AccordionItem> */}
                          </>
                        }
                        {accordianItem > 0 &&
                          <>
                            {/* <Divider mt={4} mb={5} borderColor="blackAlpha.500" /> */}
                            <Flex mt={5} mb={2}>
                              <Text
                                // textUnderlineOffset={5} textDecoration={'underline'} 
                                variant={'checkout_header'}>PACKAGING</Text>
                              {accordianItem > 1 &&
                                <Text onClick={() => changeAccordian(1)} textAlign={'right'} flex="1" variant={'change_text'}>CHANGE</Text>
                              }
                            </Flex>

                            {/* <Divider mb={2} borderColor="blackAlpha.500"/> */}

                            {/* <AccordionItem id="Shipping" title="SHXIPPING"
                        disabled={!showShipping && addressRequired}
                      > */}
                            {/* {(showShipping || !addressRequired) && */}
                            <Box>
                              <ShippingForm reload={fetchShipping} allowSelection={accordianItem == 1} globalState={globalState} />
                            </Box>

                            {/* } */}
                            {/* </AccordionItem> */}
                          </>
                        }


                        {/* </Accordion> */}

                      </Box>
                      {/* } */}

                      {!!status && <Box textTransform={'uppercase'} color="red">{status}</Box>}






                      {/* </Form> */}
                      {/* )} */}
                      {/* </Formik> */}

                      {/* <CheckboxSingleControl marginTop={2} name="marketsds" id="marketsds" label="Receive Newsletter / Promotions" /> */}
                    </Box>
                  </Elements>

                </>
              }
            </Box>
          </Center>
        </Box>
        <Center>
          <Box maxW={'600px'} width={"100%"} mt={2}>
            {!accepts_marketing &&
              <Checkbox
                isChecked={marketing}
                onChange={(e) =>
                  updateGlobal({ marketing: e.target.checked })
                }
              >
                RECEIVE NEWSLETTER / PROMOTIONS
              </Checkbox>

            }
            <Box>
              {!splitView &&
                <ShoppingCartTotals globalState={globalState} />
              }
              <CartError />
              <Center>

                {addressRequired && !showShipping ?
                  <Button
                    // form="address"
                    type={'button'}
                    mb={[5,5,0,0]}
                    variant={'checkout'}
                    onClick={(event) => handleCheckoutSubmit(event)}
                    isDisabled={!isOnline || processing || calculating || !addressComplete}
                    // onClick={handleSubmit}
                    rightIcon={calculating ? <Dots/> : <ArrowForwardIcon />}
                  // isFullWidth
                  >
                    {processing ? "PROCESSING" : calculating ? "" : !showShipping ? "CONTINUE TO PACKAGING" : "CONTINUE TO PAYMENT"}</Button>
                  :
                  <Button variant={'checkout'}
                    mb={[5,5,0,0]}
                    type={'button'}
                    isDisabled={!isOnline || processing || calculating}
                    rightIcon={processing || calculating ? <Dots /> : <ArrowForwardIcon />}
                    onClick={() => pressButton(true)}
                  // onClick={() => setShowPayment(true)}
                  >
                    {buttonMessage()}
                  </Button>
                }

              </Center>

            </Box>
          </Box>
        </Center>
      </Flex>
      {/* </form> */}

    </ViewHeightComponent>

  )
}


export default Checkout